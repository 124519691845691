import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var ProductViewBox = (function (_super) {
    __extends(ProductViewBox, _super);
    function ProductViewBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop({ required: true })
    ], ProductViewBox.prototype, "data", void 0);
    ProductViewBox = __decorate([
        Component
    ], ProductViewBox);
    return ProductViewBox;
}(Vue));
export default ProductViewBox;
