import { __decorate, __extends } from "tslib";
import { Component, Watch, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import TabList from '@/components/TabList.vue';
import ProductViewBox from '@/components/ProductViewBox.vue';
import Pagination from '@/components/Pagination.vue';
import { systemModule } from '@/store/modules/system';
import { detailsModule } from '@/store/modules/details';
var ProductCenter = (function (_super) {
    __extends(ProductCenter, _super);
    function ProductCenter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.page = 1;
        _this.rows = 6;
        return _this;
    }
    ProductCenter.prototype.mounted = function () {
        this.getProductList();
    };
    Object.defineProperty(ProductCenter.prototype, "navList", {
        get: function () {
            return systemModule.navList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductCenter.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductCenter.prototype, "currentID", {
        get: function () {
            return detailsModule.productID;
        },
        set: function (val) {
            detailsModule.setProductID(val);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductCenter.prototype, "productList", {
        get: function () {
            return detailsModule.productList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductCenter.prototype, "total", {
        get: function () {
            return detailsModule.productTotal;
        },
        enumerable: false,
        configurable: true
    });
    ProductCenter.prototype.pageWatch = function () {
        this.getProductList();
    };
    ProductCenter.prototype.setPage = function () {
        this.page = 1;
    };
    ProductCenter.prototype.getProductList = function () {
        detailsModule.getProductList({
            page: this.page,
            rows: this.rows,
            id: this.currentID
        });
    };
    __decorate([
        Watch('$data.page')
    ], ProductCenter.prototype, "pageWatch", null);
    ProductCenter = __decorate([
        Component({
            components: { CustomHead: CustomHead, TabList: TabList, ProductViewBox: ProductViewBox, Pagination: Pagination }
        })
    ], ProductCenter);
    return ProductCenter;
}(Vue));
export default ProductCenter;
